<template>
  <div class="grid">
    <div class="col-12">
      <LoansTable></LoansTable>
    </div>
  </div>
</template>

<script>
import LoansTable from "@/components/tables/LoansTable";
export default {
  name: "Loans",
  components: {LoansTable}
}
</script>

<style scoped>

</style>