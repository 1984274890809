<template>
  <div>
    <Card>
      <template #title>Займы</template>
      <template #content>
        <AbstractTable code="loans" display-filter="menu">
          <template #id="row">
            <div>
              <IdChip
                :id="row.data.id"
                view="loan_view"
                toast-template='Идентификатор займа "{v}" скопирован'
              />
            </div>
          </template>
          <template #uuid="row">
            <ValueChip
              :value="row.data.uuid"
              toast-template='UUID займа "{v}" скопирован'
            />
          </template>
          <template #amount="row">
            {{ $filters.currency(row.data.amount) }}
          </template>
          <template #fio="row">
            {{ row.data.borrower.surname }} {{ row.data.borrower.name }} {{ row.data.borrower.patronymic }}
          </template>
          <template #phone="row">
            {{ row.data.borrower.phone }}
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import ValueChip from "@/components/Common/ValueChip.vue";
import IdChip from "@/components/Common/IdChip.vue";
export default {
  name: "LoansTable",
  components: {IdChip, ValueChip, AbstractTable}
}
</script>

<style scoped>

</style>